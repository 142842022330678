@tailwind base;
@tailwind components;
@tailwind utilities;

#__react-alert__ > div > div {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker__time-box,
.react-datepicker__time-container {
  width: 100px !important;
}

.pulsate-back {
  -webkit-animation: pulsate-back 0.3s ease both;
  animation: pulsate-back 0.3s ease both;
}

/**
 * ----------------------------------------
 * animation pulsate-bck
 * ----------------------------------------
 */
@-webkit-keyframes pulsate-back {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes pulsate-back {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.95);
    transform: scale(0.95);
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

/* SPINNER */

@keyframes spinner {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.spinner div {
  position: absolute;
  width: 45px;
  height: 45px;
  border: 5px solid #85a2b6;
  border-top-color: transparent;
  border-radius: 50%;
}
.spinner div {
  animation: spinner 1s linear infinite;
  top: 50%;
  left: 50%;
}
.spinner-container {
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  position: fixed;
  opacity: 0.7;
  z-index: 1;
}
.spinner {
  width: 100%;
  height: 100%;
  background: #f1f2f3;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.spinner div {
  box-sizing: content-box;
}

/* Button spinner */

.spinner-button {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
  display: inline-block;
  vertical-align: text-bottom;
  margin-bottom: 2px;
  margin-right: 3px;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: spinner-button 0.75s linear infinite;
  animation: spinner-button 0.75s linear infinite;
}

@keyframes spinner-button {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.form-input {
  border-color: #d2d6dc66 !important;
}
